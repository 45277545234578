
let saveToken=(token)=>{
    localStorage.setItem('token',token)
}

let logOut=()=>{
    localStorage.removeItem('token')
    localStorage.removeItem('emailUser');
}

let isLogged=()=>{
    if(typeof localStorage.token !== 'undefined' && localStorage.token!=='') return true;
    else return false;

}

let getToken=()=>{
    return localStorage.getItem('token')
}

let getURL=()=>{
    console.log(window.location.href)
    let URL= 'https://www.admin.soleilduloir.florifounelle.fr/index.php'
    return URL;
}


let isMdpProv= ()=>{
    return localStorage.getItem('isMdpProv');
}


let setEmailUser= emailUser=>{
    localStorage.setItem('emailUser',emailUser);
}

let setRoleUser= roleUser=>{
    localStorage.setItem('roleUser',roleUser);
}

let getRoleUser= ()=>{
    return localStorage.getItem('roleUser');
}

let getEmailUser=()=>{
    return localStorage.getItem('emailUser');
}

export const accountService={
    saveToken,logOut,isLogged,getToken,getURL,isMdpProv,setEmailUser,getEmailUser,setRoleUser,getRoleUser,
}

